import React, {useState} from "react";
import {Offcanvas} from "react-bootstrap";
import ClearAnswer from "../common/ClearAnswer/ClearAnswer";
import FilterBar from "./Filter/Filter";
import HaveYou from "./HaveYou/HaveYou";
import SearchHero from "./Hero/Hero";
import TravelPackage from "./TravelPackage/TravelPackage";
import {Vacation} from "../../types/types";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../types/redux/ReducerStateTypes";
import FilterBarSkeleton from "./Filter/FilterSkeleton";

interface SearchProps {
    showFaq: boolean;
    showHaveYou: boolean;
    hideHeader: boolean;
}

const Search: React.FC<SearchProps> = ({
                                           showFaq,
                                           showHaveYou,
                                           hideHeader
                                       }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isLoading: object = useSelector((state: ReducerStateTypes) => state.loading.isLoading);

    const vacationResults: {
        result: Vacation[];
        count: number
    } = useSelector((state: ReducerStateTypes) => state.vacation.vacationResults);

    return (
        <>
            <SearchHero handleShow={handleShow} hideHeader={hideHeader}/>
            <TravelPackage
                vacations={vacationResults.result}
                vacationCount={vacationResults.count}
            />

            {showFaq && <ClearAnswer/>}
            {showHaveYou && <HaveYou/>}

            <>
                <Offcanvas
                    className="filter-offcanvas"
                    show={show}
                    onHide={handleClose}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filtering</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {/* @ts-ignore */}
                        {isLoading.filter ? <FilterBarSkeleton/> : <FilterBar/>}
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        </>
    );
};

export default Search;
