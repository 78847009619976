import React from "react";
import {Form} from "react-bootstrap";
import {setSortingOptions} from "../../../actions";
import TravelPackageItem from "../../common/TravelPackageItem/TravelPackageItem";
import {LoadingState, SortOptions, Vacation} from "../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import "./SearchResults.scss";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {setPageLimit} from "../../../actions/PageAction";
import TravelPackageItemSkeleton from "../../common/TravelPackageItem/TravelPackageItemSkeleton";

interface SearchResultsProps {
    vacations?: Vacation[];
    vacationCount: number;
}

const SearchResults: React.FC<SearchResultsProps> = ({
    vacations,
    vacationCount,
}) => {

    const pageLimit: number = useSelector((state: ReducerStateTypes) => state.page.pageLimit);
    const isLoading: LoadingState = useSelector((state: ReducerStateTypes) => state.loading.isLoading);
    const currentSortOption: SortOptions | null = useSelector((state: ReducerStateTypes) => state.sorting.currentSortOption);
    const dispatch = useDispatch();

    return (
        <div className="vac-content">
            <div className="package-side">
                <span>Home / Search</span>
                <div className="top-contents">
                    <h2>We hebben {vacationCount ?? 0} vakanties gevonden! </h2>
                    <div className="select-groups">
                        <div className="d-flex justify-content-between align-items-center gap-1">
                            <p className="select-title">Sorteren:</p>

                            <div className="select-wrapper">
                                <Form.Select defaultValue={currentSortOption?.sortDirection ?? "default"} onChange={(e) => {
                                    const [sort, direction] = e.target.value.split("-");
                                    if (direction === "asc" || direction === "desc") {
                                        dispatch(setSortingOptions({name: sort, sortDirection: direction}));
                                    } else {
                                        dispatch(setSortingOptions(null));
                                    }
                                }}>
                                    <option value="default">Standaard</option>
                                    <option value="price-desc">Prijs: Hoog - laag</option>
                                    <option value="price-asc">Prijs: Laag - hoog</option>
                                    <option value="stars-desc">Sterren: Hoog - laag</option>
                                    <option value="stars-asc">Sterren: Laag - hoog</option>
                                </Form.Select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-1">
                            <p className="select-title">Vakanties per pagina:</p>

                            <div className="select-wrapper">
                                <Form.Select defaultValue={pageLimit} onChange={(e) => {
                                    dispatch(setPageLimit(parseInt(e.target.value)));
                                }} aria-label="Default select example">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={40}>40</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="package-wrapper">
                    {(vacations === undefined || vacations.length === 0) && !isLoading ? <h6>Geen vakanties gevonden</h6> : null}
                    { /* @ts-ignore */}
                    {isLoading.vacationResult ? Array.from({length: 10}, () => {
                        return <TravelPackageItemSkeleton />
                    }) : null}
                    {vacations?.map((it, i) => (
                        <TravelPackageItem trpackage="search" item={it} key={i}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SearchResults;