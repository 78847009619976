import {SET_LOADING} from "../actions";
import {ActionWithPayload} from "../types/redux/ActionWithPayload";
import {LoadingState} from "../types/types";

export interface LoadingReducerState {
    isLoading: LoadingState;
}

const initialState: LoadingReducerState = {
    isLoading: {
        filter: false,
        vacationResult: false
    }
}

const LoadingReducer = (state: LoadingReducerState = initialState, action: ActionWithPayload<object>): LoadingReducerState => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: {...state.isLoading, ...action.payload}
            }
        default:
            return state;
    }
}

export default LoadingReducer;