import Skeleton from "react-loading-skeleton";
import React from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import './TravelPackageItem.scss';

const TravelPackageItemSkeleton = () => {
    return (
        <div id="travel-package-item">
            <div className="travel-package-content-wrapper">
                <div className="">
                    <Skeleton className="d-block w-100 slide-img"/>
                </div>
                <div className="d-flex align-items-start">
                    <div style={{width: "100%"}}>
                        <div className="d-flex justify-content-between" style={{padding: "5px 0px"}}>
                            <Skeleton className='d-flex align-items-center gap-1' width={"180px"} height={"44px"}
                                      style={{borderRadius: '30px'}}/>
                            <Skeleton height={'44px'} width={'98px'} style={{borderRadius: '30px'}}/>
                        </div>
                        <h2><Skeleton height={'25px'} width={'100%'}/></h2>
                        <p><Skeleton height={'20px'} width={'100%'}/></p>
                    </div>
                </div>
            </div>

            <div className="bottom-contnets">
                <div className="box-item">
                    <div className="img-side">
                        <div className="img-content">
                            <Skeleton style={{width: "80px", height: "80px", borderRadius: "30%"}}/>
                        </div>

                        <div className="half-circle1"/>
                        <div className="half-circle2"/>
                    </div>
                    <div className="text-side">
                        <div className="text-item">
                            <h6><Skeleton width={"100px"} height={"12px"}/></h6>
                            <h5><Skeleton width={"100px"} height={"20px"}/></h5>
                            <h6><Skeleton width={"100px"} height={"12px"}/></h6>
                        </div>

                        <div className="d-flex align-item-center gap-1"
                             style={{width: "65%", justifyContent: "space-between"}}>
                            <div className="text-item">
                                <h5><Skeleton width={"100px"} height={"20px"} style={{marginBottom: "5px"}}/></h5>
                                <h5><Skeleton width={"80px"} height={"17px"}/></h5>
                            </div>
                            <div className="img-item plane-img">
                                <Skeleton width={"62px"} height={"25px"} style={{borderRadius: "30%"}}/>
                            </div>
                            <div className="text-item departure-return">
                                <h5><Skeleton width={"100px"} height={"20px"} style={{marginBottom: "5px"}}/></h5>
                                <h5><Skeleton width={"80px"} height={"17px"}/></h5>
                            </div>

                            <div className="text-item">
                                <Skeleton width={"70px"} height={"25px"}/>
                                <Skeleton width={"70px"} height={"12px"}/>
                            </div>
                            <Skeleton width={"132px"} height={"44px"} style={{borderRadius: "30px"}}/>
                        </div>
                    </div>
                    <div className="text-side-sm-device w-100">
                        <div className="d-flex justify-content-between w-100">
                            <div className="text-item">
                                <h6><Skeleton width={"100px"} height={"12px"}/></h6>
                                <h5><Skeleton className="break-word" width={"100px"} height={"20px"}/></h5>
                                <h6><Skeleton width={"100px"} height={"12px"}/></h6>
                            </div>
                            <div className="text-item">
                                <Skeleton width={"70px"} height={"25px"}/>
                                <Skeleton width={"70px"} height={"12px"}/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <div className="text-item">
                                <h6><Skeleton width={"100px"} height={"12px"}/></h6>
                                <h5><Skeleton width={"100px"} height={"20px"}/></h5>
                            </div>
                        </div>
                        <Skeleton width={"132px"} height={"44px"} style={{borderRadius: "30px"}}/>
                    </div>

                </div>
            </div>

            {/* @todo implement see more functionality in backend */}
            {/*{trpackage === "search" && <button className="more-btn">2 Meer</button>}*/}
        </div>

    );
}

export default TravelPackageItemSkeleton;