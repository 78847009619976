import Home from './components/home/Index';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/global/Footer/Footer';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import Search from './components/search/Index';
import React, {useEffect, useState} from 'react';
import FilterBar from "./components/search/Filter/Filter";
import {FilterSearch, SortOptions, Vacation} from "./types/types";
import {vacationService} from "./services/VacationService";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "./types/redux/ReducerStateTypes";
import SearchResults from "./components/search/SearchResults/SearchResults";
import {setPluginOptions, setVacationResults, setLoading, setAvailableFilters} from "./actions";
import SearchBar from "./components/common/SearchBar/SearchBar";
import {filterService} from "./services/FilterService";

function App({pluginOptions}) {

    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
    const participantAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
    const currentSortOptions: SortOptions | null = useSelector((state: ReducerStateTypes) => state.sorting.currentSortOption);
    const vacationResults: {
        result: Vacation[];
        count: number
    } = useSelector((state: ReducerStateTypes) => state.vacation.vacationResults);
    const filterChanged: boolean = useSelector((state: ReducerStateTypes) => state.filter.filterChanged);
    const pageLimit: number = useSelector((state: ReducerStateTypes) => state.page.pageLimit);
    const [searchParams] = useSearchParams();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPluginOptions(pluginOptions));
    }, [dispatch, pluginOptions]);

    useEffect(() => {
        dispatch(setLoading({vacationResult: true}));
        vacationService.getFilteredVacations(
            pluginOptions,
            selectedFilters,
            (participantAmountAdults + participantAmountChildren),
            currentSortOptions,
            !filterChanged,
            searchParams.get("page"),
            pageLimit,
            searchParams.get("filter") ?? ""
        ).then(vacations => {
            dispatch(setVacationResults(vacations));
            dispatch(setLoading({vacationResult: false}));
        });
    }, [pluginOptions, currentSortOptions, filterChanged, pageLimit, participantAmountAdults, participantAmountChildren, searchParams, selectedFilters, dispatch]);

    useEffect(() => {
        dispatch(setLoading({filter: true}));
        filterService.getFilters(
            selectedFilters,
            searchParams.get("filter") ?? "",
            pluginOptions,
        ).then(filters => {
            dispatch(setAvailableFilters(filters));
            dispatch(setLoading({filter: false}));
        });
    }, [dispatch, pluginOptions, searchParams, selectedFilters]);

    if (pluginOptions.partial !== 'full-page') {
        switch (pluginOptions.partial) {
            case 'full-tool':
                return <Search showFaq={false} showHaveYou={false} hideHeader/>;
            case 'search-bar':
                return <SearchBar isSearchPage={false}/>
            case 'result-page':
                return <SearchResults
                    vacationCount={vacationResults.count}
                    vacations={vacationResults.result}
                />
            case 'filter-bar':
                return <FilterBar/>;
            default:
                console.error('Invalid pluginOptions: partial must be one of full-page, full-tool, search-bar, filter-bar, result-page, result-blocks');
                return null;
        }
    }

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Home/>
                    }
                />
                <Route
                    path="/search"
                    element={
                        <Search showFaq showHaveYou hideHeader={false}/>
                    }
                />
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
