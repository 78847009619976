import React from "react";
import {DownArrow, FilterIcon} from "../../../Icons";
import {Accordion} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const FilterBarSkeleton: React.FC = () => {

    const renderFilterSkeleton = (key: number) => {
        return (
            <div key={key} className="rating">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            {" "}
                            <h5 className="w-100 m-0">
                                <Skeleton width={'150px'} height={'14px'}/>
                                <div className="dropdown-icon">
                                    <DownArrow/>
                                </div>
                            </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                            {Array.from({length: 4}).map((_, index) => (renderFilterItem(index)))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        );
    }

    const renderFilterItem = (key: number) => {
        return (
            <div key={key} className="check-box-wrapper">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <input
                            type="checkbox"
                            disabled
                        />
                        <Skeleton height={'12px'} width={'120px'}/>
                    </div>
                    <h6>
                        <Skeleton height={'12px'} width={'20px'}/>
                    </h6>
                </div>
            </div>
        );
    }

    return (
        // @ts-ignore
        <div className="filter-box">
            <div className="top-section">
                <div className="d-flex align-items-center gap-2">
                    <FilterIcon/>
                    <h4>Filters</h4>
                </div>
            </div>
            {Array.from({length: 5}).map((_, index) => (renderFilterSkeleton(index)))}
        </div>
    );
}

export default FilterBarSkeleton;